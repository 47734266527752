import { BodyText, Button, GridCol, GridRow, Heading } from '@lmig/lmds-react'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ccpaAccepted } from '../../actions/raterActions'
import history from '../../helpers/history'
import Card from '../Elements/Card'
import './CcpaMessage.scss'

export const DISCLOSURE_TEXT_STANDARD =
  'As part of the quoting process, I can quote other insurance companies for you. ' +
  'It will save you time because I can share the information you provided in your application. ' +
  'Our partner carriers may obtain information from consumer reports about your ' +
  'loss history, credit history and driving record. ' +
  'Do I have your consent to continue?'

const CcpaMessage = () => {
  const dispatch = useDispatch()
  const { ccpaVisible, queryParameters } = useSelector(({ rater, router }) => ({
    ccpaVisible: rater.ccpaVisible,
    queryParameters: router.location.query,
  }))

  const declineCcpa = useCallback(() => {
    history.push('/endsession')
  }, [])

  const acceptCcpa = useCallback(() => {
    dispatch(ccpaAccepted(queryParameters))
  }, [queryParameters, dispatch])

  if (!ccpaVisible) {
    return null
  }

  return (
    <Card
      title="Consumer Data Transfer Consent"
      className="ccpaMessage cardCentered"
    >
      <GridRow gutters justify="center" vAlign="middle">
        <GridCol base="flex-auto">
          <Heading type="h4-light" align="center">
            Please state the following to the consumer:
          </Heading>
          <BodyText type="article">
            <p id="ccpa-message-text">{DISCLOSURE_TEXT_STANDARD}</p>
          </BodyText>
          <Heading type="h4-light" align="center">
            Did the consumer give consent?
          </Heading>
          <div className="buttons">
            <Button
              size="medium"
              level="secondary"
              id="ccpa-button-decline"
              onClick={declineCcpa}
            >
              No
            </Button>
            <Button size="medium" id="ccpa-button-accept" onClick={acceptCcpa}>
              Yes
            </Button>
          </div>
        </GridCol>
      </GridRow>
    </Card>
  )
}

export default CcpaMessage
